<div class="flex gap-x-2">
  <tui-input
    class="w-6/12"
    tuiTextfieldSize="m"
    [formControl]="form.controls.cargoPlaceTotal"
    >Заполняемость авто</tui-input
  >
  <tui-input-number
    class="w-2/12"
    tuiTextfieldSize="m"
    [formControl]="form.controls.boxCount"
    >Общее кол-во коробок</tui-input-number
  >
  <tui-input
    class="w-2/12"
    tuiTextfieldSize="m"
    [formControl]="form.controls.sum"
  >
    Общая сумма заказа
  </tui-input>
</div>
