import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  OnInit,
} from '@angular/core';
import { TuiIslandModule } from '@taiga-ui/kit';
import { AsyncPipe, NgForOf } from '@angular/common';
import { TuiTableModule } from '@taiga-ui/addon-table';
import { TuiLetModule } from '@taiga-ui/cdk';
import {
  map,
  Observable,
  combineLatest,
  withLatestFrom,
  switchMap,
} from 'rxjs';
import { Store } from '@ngxs/store';
import {
  getCargoPlaceQuantityByTrailerType,
  TrailerType,
} from '@shared/constants/vehicles';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { roundDecimal } from '@shared/utils/math';
import { RateService } from '@shared/services/rate.service';
import { CargoState } from '../../store/cargo.state';
import { Cargo, CargoOrder, CargoOrderGroup } from '../../../../types/cargo';
import { CatalogsService } from '../../services/catalogs.service';
import { CargoService } from '../../services/cargo.service';

const NO_TYPE = '(без типа)';
type OrderGroupTotal = {
  boxCount: number;
  totalWeight: number;
  cargoPlaceTotal: Record<string, number>;
  cargoPlaceTotalNormalized: string;
  rateTotal: Record<string, number>;
  rateTotalNormalized: string;
};

type OrderGroupByClient = Pick<CargoOrderGroup, 'clientName'> & OrderGroupTotal;

@Component({
  selector: 'app-payments',
  standalone: true,
  imports: [TuiIslandModule, AsyncPipe, NgForOf, TuiTableModule, TuiLetModule],
  templateUrl: './payments.component.html',
  styleUrl: './payments.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentsComponent implements OnInit {
  tableHeaders: Array<{ name: string; value: keyof OrderGroupByClient }> = [
    {
      name: 'Клиент',
      value: 'clientName',
    },
    {
      name: 'Коробки',
      value: 'boxCount',
    },
    {
      name: 'Грузовые места',
      value: 'cargoPlaceTotalNormalized',
    },
    {
      name: 'Вес',
      value: 'totalWeight',
    },
    {
      name: 'Ставка',
      value: 'rateTotalNormalized',
    },
  ];

  // totalCost$ = new BehaviorSubject(0);
  // totalQuantity$ = new BehaviorSubject(0);

  groups$ = this.store.select(CargoState.getOrderGroups);

  orderGroupsByClient$: Observable<OrderGroupByClient[]> = combineLatest([
    this.groups$,
    this.catalogs.cargoPlaces$,
    this.catalogs.rates$,
  ])
    .pipe(
      map(([groups]) => {
        const clients: Record<string, OrderGroupByClient> = {};

        const total = groups.reduce((previousValue, cargoOrderGroup) => {
          const clientName = cargoOrderGroup.clientName || '(без названия)';
          const ordersTotal = this.getOrdersTotal(cargoOrderGroup.orders);

          return {
            ...previousValue,
            [clientName]: {
              cargoPlaceTotalNormalized: '',
              rateTotalNormalized: '',
              clientName,
              boxCount:
                (previousValue[clientName]?.boxCount ?? 0) +
                (ordersTotal.boxCount ?? 0),
              totalWeight:
                (previousValue[clientName]?.totalWeight ?? 0) +
                (ordersTotal.totalWeight ?? 0),
              cargoPlaceTotal: this.getCargoPlaceTotal(
                ordersTotal.cargoPlaceTotal ?? {},
                previousValue[clientName]?.cargoPlaceTotal ?? {},
              ),
              rateTotal: this.getRateTotal(
                cargoOrderGroup,
                ordersTotal,
                previousValue[clientName]?.rateTotal ?? {},
              ),
            },
          };
        }, clients);

        return Object.values(total);
      }),
    )
    .pipe(map(items => this.getNormalizedClientGroups(items)));

  cargo$ = this.store.select(CargoState.getSelectedCargo);
  constructor(
    private readonly store: Store,
    private readonly catalogs: CatalogsService,
    private readonly cargoService: CargoService,
    private readonly destroyRef: DestroyRef,
    private readonly rateService: RateService,
  ) {}

  getRateTotal(
    orderGroup: CargoOrderGroup,
    orderGroupTotal: OrderGroupTotal,
    prevRateTotal: OrderGroupTotal['rateTotal'],
  ): OrderGroupTotal['rateTotal'] {
    const rateMap = this.catalogs.rateMap;
    const cargoPlaceMap = this.catalogs.cargoPlaceMap;
    const trailer = getCargoPlaceQuantityByTrailerType(
      this.store.selectSnapshot(CargoState.getSelectedCargo)
        ?.trailerType as TrailerType,
    );
    const total = Object.entries(orderGroupTotal?.rateTotal ?? {}).reduce(
      (previousValue, [cargoPlaceType, quantity]) => {
        const rateKey = this.rateService.getMapKey({
          cargoPlaceCode: cargoPlaceType,
          flowerTypeId: orderGroup.flowerType,
          imported: orderGroup.imported,
        });
        const rateDetails = rateMap.get(rateKey);
        const currencyLetter = rateDetails?.currencyLetter ?? NO_TYPE;
        const limit = trailer
          ? cargoPlaceMap.get(cargoPlaceType)?.[trailer] ?? 0
          : 0;

        const rate = rateDetails?.rate ?? 0;
        const cost = rateDetails?.imported
          ? rate * (orderGroupTotal.totalWeight ?? 0)
          : (rate / limit) * quantity;
        const sum = (previousValue?.[currencyLetter] ?? 0) + cost;

        return {
          ...previousValue,
          [currencyLetter]:
            Number.isNaN(sum) || !Number.isFinite(sum) ? 0 : sum,
        };
      },
      prevRateTotal,
    );
    return this.prepareNumericValues(total);
  }

  prepareNumericValues<T extends Record<string, number>>(total: T): T {
    return Object.entries(total).reduce((previousValue, [key, value]) => {
      return {
        ...previousValue,
        [key]: roundDecimal(value),
      };
    }, {} as T);
  }

  getNormalizedClientGroups(items: OrderGroupByClient[]) {
    const cargoPlaceMap = this.catalogs.cargoPlaceMap;
    return items.map(item => {
      return {
        ...item,
        cargoPlaceTotalNormalized: Object.entries(item.cargoPlaceTotal).reduce(
          (prev, [cargoPlaceType, quantity], currentIndex, arr) => {
            return `${prev}`.concat(
              `${quantity} ${cargoPlaceMap.get(cargoPlaceType)?.name ?? NO_TYPE} ${currentIndex === arr.length - 1 ? '' : '/'} `,
            );
          },
          '',
        ),
        rateTotalNormalized: Object.entries(item.rateTotal).reduce(
          (prev, [currencyLetter, sum], currentIndex, arr) => {
            return `${prev}`.concat(
              `${sum} ${currencyLetter} ${currentIndex === arr.length - 1 ? '' : '/'} `,
            );
          },
          '',
        ),
      };
    });
  }

  getCargoPlaceTotal(
    cargoPlaceTotal: OrderGroupTotal['cargoPlaceTotal'],
    prevCargoPlaceTotal: OrderGroupTotal['cargoPlaceTotal'],
  ): OrderGroupTotal['cargoPlaceTotal'] {
    const total = Object.entries(cargoPlaceTotal).reduce(
      (previousValue, [placeType, value], index, array) => {
        const quantity = (previousValue?.[placeType] ?? 0) + value;
        return {
          ...previousValue,
          [placeType]: quantity,
        };
      },
      prevCargoPlaceTotal,
    );
    return this.prepareNumericValues(total);
  }

  getOrdersTotal(orders: CargoOrder[]): OrderGroupTotal {
    const groupTotal: OrderGroupTotal = {} as OrderGroupTotal;

    return orders.reduce((previousValue, currentValue) => {
      const cargoPlaceType = currentValue.cargoPlaceTypeName ?? NO_TYPE;
      return {
        cargoPlaceTotalNormalized: '',
        rateTotalNormalized: '',
        boxCount:
          (previousValue.boxCount ?? 0) + (currentValue.packageQuantity ?? 0),
        totalWeight:
          (previousValue.totalWeight ?? 0) + (currentValue.weight ?? 0),
        rateTotal: {
          ...previousValue.rateTotal,
          [cargoPlaceType]:
            (previousValue?.rateTotal?.[cargoPlaceType] ?? 0) +
            (currentValue.cargoPlaceQuantity ?? 0),
        },
        cargoPlaceTotal: {
          ...previousValue.cargoPlaceTotal,
          [cargoPlaceType]:
            (previousValue.cargoPlaceTotal?.[cargoPlaceType] ?? 0) +
            (currentValue.cargoPlaceQuantity ?? 0),
        },
      };
    }, groupTotal);
  }

  get columns() {
    return [...this.tableHeaders.map(c => c.value)];
  }

  getCargoPlacesQuantityArray(groupsByClient: OrderGroupByClient[]) {
    const cargoPlaces = groupsByClient
      .map(g => g.cargoPlaceTotal)
      .reduce((prev, total) => {
        return Object.entries(total).reduce((previousValue, [key, value]) => {
          return {
            ...previousValue,
            [key]: (previousValue[key] ?? 0) + value,
          };
        }, prev);
      }, {});
    return Object.entries(this.prepareNumericValues(cargoPlaces)).map(
      ([type, quantity]) => {
        return {
          quantity,
          type,
        };
      },
    );
  }

  getCargoPaymentsArray(groupsByClient: OrderGroupByClient[]) {
    const payments = groupsByClient
      .map(g => g.rateTotal)
      .reduce((prev, total) => {
        return Object.entries(total).reduce((previousValue, [key, value]) => {
          return {
            ...previousValue,
            [key]: (previousValue[key] ?? 0) + value,
          };
        }, prev);
      }, {});
    return Object.entries(this.prepareNumericValues(payments)).map(
      ([currencyLetter, sum]) => {
        return {
          currencyLetter,
          sum,
        };
      },
    );
  }

  ngOnInit() {
    this.cargoService.saveRecalculatedValues$
      .pipe(
        withLatestFrom(this.orderGroupsByClient$),
        map(([, groupsByClient]) => {
          return {
            cargoPlacesQuantityJson:
              this.getCargoPlacesQuantityArray(groupsByClient),
            paymentsJson: this.getCargoPaymentsArray(groupsByClient),
          };
        }),
        withLatestFrom(this.cargo$),
        switchMap(([placesAndPayments, cargo]) => {
          const payload: Cargo = {
            ...(cargo as Cargo),
            ...placesAndPayments,
          };
          return this.cargoService.updateCargo(payload);
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }
}
