import {
  Component,
  viewChild,
  AfterViewInit,
  DestroyRef,
  ChangeDetectionStrategy,
} from '@angular/core';
import { TuiInputModule, TuiInputPasswordModule } from '@taiga-ui/kit';
import {
  TuiButtonComponent,
  TuiButtonModule,
  TuiHintModule,
  TuiTextfieldControllerModule,
} from '@taiga-ui/core';
import {
  FormBuilder,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { catchError, EMPTY, fromEvent, switchMap, tap } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Store } from '@ngxs/store';
import { Router, RouterLink } from '@angular/router';
import { AlertService } from '@shared/services/alert.service';
import { Login } from '@store/auth/auth.actions';
import { RouteNames } from '../../../../route-names';

@Component({
  selector: 'app-user-login',
  standalone: true,
  templateUrl: './user-login.component.html',
  styleUrl: './user-login.component.scss',
  imports: [
    TuiInputModule,
    TuiTextfieldControllerModule,
    TuiInputPasswordModule,
    TuiButtonModule,
    TuiHintModule,
    FormsModule,
    ReactiveFormsModule,
    RouterLink,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserLoginComponent implements AfterViewInit {
  protected submitButton = viewChild<TuiButtonComponent>('submit');

  form = this.fb.group({
    login: ['', Validators.required],
    password: ['', Validators.required],
  });

  protected readonly RouteNames = RouteNames;
  constructor(
    private readonly router: Router,
    private readonly destroyRef: DestroyRef,
    private readonly alertService: AlertService,
    private readonly store: Store,
    private readonly fb: FormBuilder,
  ) {}

  ngAfterViewInit() {
    const component = this.submitButton();
    const element = component?.nativeFocusableElement as HTMLElement;
    fromEvent(element, 'click')
      .pipe(
        switchMap(() => this.authenticate()),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  authenticate() {
    const { login, password } = this.form.getRawValue();
    return this.store
      .dispatch(
        new Login({
          login: login ?? '',
          password: password ?? '',
        }),
      )
      .pipe(
        tap(() => {
          this.router.navigate([RouteNames.KANBAN_PAGE]);
        }),
        catchError(err => {
          this.alertService.showError(err.error);
          return EMPTY;
        }),
      );
  }
}
