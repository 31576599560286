<tui-island class="min-w-[430px] p-6">
  <h2
    class="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl"
  >
    Восстановить пароль
  </h2>
  <div class="flex flex-col gap-y-2 my-6">
    <tui-input [formControl]="form.controls.email" tuiTextfieldSize="l"
      >Email</tui-input
    >
    @if (form.controls.resetPasswordToken.value && form.controls.email.value) {
      <tui-input-password
        [formControl]="form.controls.password"
        tuiTextfieldSize="m"
        >Пароль</tui-input-password
      >
      <tui-input-password
        [formControl]="form.controls.passwordConfirm"
        tuiTextfieldSize="m"
        >Подтверждение пароля</tui-input-password
      >
    }
  </div>
  <button
    (click)="submit()"
    [disabled]="!form.valid"
    class="w-full"
    tuiButton
    appearance="primary"
  >
    {{
      form.controls.resetPasswordToken.value ? 'Применить' : 'Отправить письмо'
    }}
  </button>
</tui-island>
