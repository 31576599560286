import { concat, defer, Observable, of } from 'rxjs';
import { FormControl } from '@angular/forms';

export function waitPromises(requests: Array<Promise<any>>) {
  return new Observable(observer => {
    Promise.all(requests).then(() => {
      observer.next();
      observer.complete();
    });
  });
}

export function fromFormControlValue<T>(
  control: FormControl<T>,
): Observable<T> {
  return concat(
    defer(() => of(control.value)),
    control.valueChanges,
  );
}
