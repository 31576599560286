<nav class="navbar relative">
  <button
    [routerLink]="RouteNames.MAIN_PAGE"
    tuiButton
    appearance="mono"
    size="m"
  >
    <div class="flex">
      GREEN<span class="inline-block bg-[var(--tui-primary)] text-white px-1"
        >LAND</span
      >
    </div>
  </button>

  <div class="absolute left-1/2 -translate-x-1/2">
    @for (item of availableMenuItems(); track $index) {
      <button
        (click)="navigate(item)"
        tuiButton
        appearance="mono"
        size="m"
        type="button"
      >
        {{ item.name }}
      </button>
    }
    @if (isAuthenticated()) {
      <app-dropdown-menu></app-dropdown-menu>
    }
  </div>

  @if (isAuthenticated()) {
    <app-user-menu></app-user-menu>
  } @else {
    <button
      tuiIconButton
      appearance="outline"
      size="m"
      type="button"
      icon="tuiIconLogIn"
      [routerLink]="RouteNames.LOGIN_PAGE"
    ></button>
  }
</nav>
