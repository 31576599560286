<form (keyup.enter)="authenticate()" class="login-form" [formGroup]="form">
  <div class="w-full bg-white rounded-lg shadow md:mt-0 sm:max-w-md xl:p-0">
    <div class="login-form__card">
      <h1 class="login-form__card-title">Войти в аккаунт</h1>
      <div class="space-y-4 md:space-y-6">
        <div>
          <tui-input [formControl]="form.controls.login" tuiTextfieldSize="l">
            Логин
            <input tuiTextfield type="email" />
          </tui-input>
        </div>
        <div>
          <label class="login-form__card-label"></label>
          <tui-input-password
            [formControl]="form.controls.password"
            tuiTextfieldSize="l"
          >
            Пароль
          </tui-input-password>
        </div>

        <div class="my-3">
          <button
            #submit
            size="l"
            class="w-full"
            appearance="primary"
            tuiButton
          >
            Войти
          </button>
          <div
            class="mt-3 text-center cursor-pointer"
            [routerLink]="['/', RouteNames.RESET_PASSWORD_PAGE]"
          >
            Восстановить пароль
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
