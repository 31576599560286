import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class OrderGroupStateService {
  requests: Array<Promise<unknown>> = [];
  clearRequests() {
    this.requests = [];
  }

  getRequests() {
    return this.requests;
  }

  pushRequest(request: Promise<unknown>) {
    this.requests.push(request);
  }
}
