import { Inject, Injectable } from '@angular/core';
import { APP_ENVIRONMENT } from '@shared/providers/environment.provider';
import { HttpClient } from '@angular/common/http';
import { Environment } from '../../types/environment';
import { FlowerType } from '../../types/flower-type';

@Injectable({
  providedIn: 'root',
})
export class FlowerTypeService {
  readonly API = `${this.env.apiUrl}/flowerTypeClassifier`;
  constructor(
    @Inject(APP_ENVIRONMENT) private readonly env: Environment,
    private readonly http: HttpClient,
  ) {}

  save(flowerType: FlowerType) {
    return this.http.post<FlowerType>(`${this.API}/save`, flowerType);
  }

  getById(id: number) {
    return this.http.get<FlowerType>(`${this.API}/getById?id=${id}`);
  }

  getAll() {
    return this.http.get<FlowerType[]>(`${this.API}/getAll`);
  }

  toFlowerTypeMap(
    value: FlowerType[],
    key: keyof FlowerType,
  ): Map<string, FlowerType> {
    return value.reduce((previousValue, currentValue) => {
      return previousValue.set(currentValue[key]?.toString(), currentValue);
    }, new Map());
  }
}
