<div
  class="max-w-[1400px] mx-auto"
  (tuiActiveZoneChange)="onZoneChange($event)"
>
  <div class="flex gap-6">
    <div class="w-3/12" tuiGroup [collapsed]="true">
      <tui-input-number [formControl]="form.controls.id" tuiTextfieldSize="m"
        >ID</tui-input-number
      >
      <app-input-date
        [formControl]="form.controls.creationDate"
        class="tui-group__inherit-item"
        label="Дата"
      ></app-input-date>
    </div>
    <div class="w-3/12" tuiGroup [collapsed]="true">
      <app-autocomplete
        [formControl]="form.controls.transportType"
        class="tui-group__inherit-item w-1/2"
        componentClass="tui-group__inherit-item"
        [items]="vehicles"
        [itemValue]="'value'"
        [itemText]="'name'"
        [displayValue]="'name'"
      >
      </app-autocomplete>
      <app-autocomplete
        [formControl]="form.controls.trailerType"
        class="tui-group__inherit-item w-1/2"
        componentClass="tui-group__inherit-item"
        [items]="trailers"
        [itemValue]="'value'"
        [itemText]="'name'"
        [displayValue]="'name'"
      >
      </app-autocomplete>
    </div>
    <app-autocomplete
      class="w-2/12"
      [formControl]="form.controls.sellerId"
      [items]="sellers$ | async"
      [itemValue]="'id'"
      [itemText]="'name'"
      [displayValue]="'name'"
      [itemSearch]="'search'"
      (valueChanged)="fillSellerName($event)"
    >
    </app-autocomplete>
    <div class="w-[150px]">
      <app-input-date [formControl]="form.controls.uploadDate"></app-input-date>
    </div>
  </div>
</div>
