import {
  HttpErrorResponse,
  HttpEvent,
  HttpEventType,
  HttpHandlerFn,
  HttpInterceptorFn,
  HttpRequest,
  HttpResponse,
  HttpStatusCode,
} from '@angular/common/http';
import {
  BehaviorSubject,
  catchError,
  filter,
  map,
  Observable,
  switchMap,
  take,
  tap,
  throwError,
} from 'rxjs';
import { Store } from '@ngxs/store';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import {
  convertPropsToCamelCase,
  convertPropsToSnakeCase,
  replaceEmptyStringsWithNull,
} from '@shared/utils/common';
import { AuthState } from '@store/auth/auth.state';
import { Logout, RefreshAuth } from '@store/auth/auth.actions';
import { AuthService } from '@shared/services/auth.service';
import { RouteNames } from './route-names';

function isAllowedBodyType(event: HttpResponse<unknown>) {
  return ![Blob, ArrayBuffer].some(type => event.body instanceof type);
}

function isAllowedToken(req: HttpRequest<unknown>) {
  return !req.url.includes('web.declarant.by');
}

function isAllowedUrl(event: HttpResponse<unknown>) {
  const except = [
    '/api/nsi/common/getLastUpdated',
    '/declaration/flk',
    '/declaration/validatePayments',
    'api/nsi/conformityCertificate/getByNumbers',
  ];
  return !except.some(u => event?.url?.includes(u));
}

function isUnauthorized(err: HttpErrorResponse) {
  return HttpStatusCode.Unauthorized === err.status;
}

function isBadRequest(err: HttpErrorResponse) {
  return HttpStatusCode.BadRequest === err.status;
}

function isForbidden(err: HttpErrorResponse) {
  return HttpStatusCode.Forbidden === err.status;
}
function camelCaseBody(stream$: Observable<HttpEvent<unknown>>) {
  return stream$.pipe(
    map(event => {
      if (
        event.type === HttpEventType.Response &&
        isAllowedUrl(event) &&
        isAllowedBodyType(event)
      ) {
        return event.clone({ body: convertPropsToCamelCase(event.body) });
      }
      return event;
    }),
  );
}

const jwtSubject$ = new BehaviorSubject<Nullable<string>>(null);
const isRefreshing$ = new BehaviorSubject(false);
export const httpInterceptor: HttpInterceptorFn = (req, next) => {
  const store = inject(Store);
  const router = inject(Router);
  const auth = inject(AuthService);
  const refreshToken = store.selectSnapshot(AuthState.getRefreshToken);
  const token = store.selectSnapshot(AuthState.getToken);

  const handleRefreshError = (err: unknown) => {
    isRefreshing$.next(false);
    if (err instanceof HttpErrorResponse) {
      if (isUnauthorized(err) || isBadRequest(err)) {
        store.dispatch(new Logout());
        router.navigate([RouteNames.LOGIN_PAGE]);
      }
    }
    return throwError(() => err);
  };

  const addToken = (req: HttpRequest<unknown>, token: Nullable<string>) => {
    return req.clone({
      headers: isAllowedToken(req)
        ? req.headers.set('Authorization', `Bearer ${token}`)
        : req.headers,
    });
  };

  const authorizedRequest = addToken(req, token).clone({
    body:
      req.body instanceof FormData
        ? req.body
        : replaceEmptyStringsWithNull(
            convertPropsToSnakeCase(req.body as Record<string, any>),
          ),
  });

  const handleUnauthorizedError = (
    req: HttpRequest<unknown>,
    next: HttpHandlerFn,
    err: HttpErrorResponse,
  ) => {
    if (!token) {
      return throwError(() => err);
    }
    if (!isRefreshing$.value) {
      isRefreshing$.next(true);
      jwtSubject$.next(null);
      return auth.refreshToken(refreshToken || '').pipe(
        tap(authData => {
          isRefreshing$.next(false);
          store.dispatch(new RefreshAuth(authData));
          jwtSubject$.next(authData.jwtToken);
        }),
        switchMap(() => {
          const freshToken = store.selectSnapshot(AuthState.getToken);
          return next(addToken(req, freshToken)).pipe(camelCaseBody);
        }),
        catchError(handleRefreshError),
      );
    }
    return jwtSubject$.pipe(
      filter(jwt => !!jwt),
      take(1),
      switchMap(jwt => {
        return next(addToken(req, jwt)).pipe(camelCaseBody);
      }),
      catchError(handleRefreshError),
    );
  };

  return next(authorizedRequest).pipe(
    camelCaseBody,
    catchError((err: any) => {
      if (err instanceof HttpErrorResponse) {
        if (isUnauthorized(err)) {
          return handleUnauthorizedError(authorizedRequest, next, err);
        }
        if (isForbidden(err)) {
          router.navigate([RouteNames.FORBIDDEN_PAGE]);
        }
      }
      return throwError(() => err);
    }),
  );
};
