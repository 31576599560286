export interface Credentials {
  login: string;
  password: string;
}

export interface LoginResponse {
  jwtToken: string;
  user: User;
  role: string;
  refreshToken: string;
}

export enum Roles {
  SUPER_ADMIN = 'superadmin',
  ADMIN = 'admin',
  DECLARANT = 'declarant',
}

// TODO: replace with roles
export const SUPER_ADMINS = ['brazelkree@gmail.com', 'semenzv@gmail.com'];

export type RoleInDivision = {
  roleId: number;
  engRoleName: string;
  roleName: string;
  divisionId: number;
};
export interface User {
  id: number;
  firstName: string;
  lastName: string;
  middleName: string;
  email: string;
  phone: string;
  clientId: Nullable<number>;
  divisionId: Nullable<number>;
  roleId: Nullable<number>;
  active: boolean;
}

export type UserPersonalData = {
  id: number;
  lastName: Nullable<string>;
  firstName: Nullable<string>;
  middleName: Nullable<string>;
  phone: Nullable<string>;
  qualificationCertificate: Nullable<string>;
  identityDocName: Nullable<string>;
  identityDocCode: Nullable<string>;
  identityDocNumber: Nullable<string>;
  identityDocDOn: Nullable<string>;
  identityDocDOff: Nullable<string>;
  uidId: Nullable<number>;
  userPosition: Nullable<string>;
  authDocCode: Nullable<string>;
  authDocName: Nullable<string>;
  authDocNumber: Nullable<string>;
  authDocDOn: Nullable<string>;
  authDocDOff: Nullable<string>;
  uidPhone: Nullable<string>;
  email: Nullable<string>;
  unp: Nullable<string>;
  name: Nullable<string>;
  nastdId: Nullable<string>;
  description: Nullable<string>;
};

export type OrganizationUser = {
  id: number;
  firstName: Nullable<string>;
  lastName: Nullable<string>;
  middleName: Nullable<string>;
  email: Nullable<string>;
  phone: Nullable<string>;
  clientId: Nullable<number>;
  divisionId: number;
  roleId: number;
  active: boolean;
};

export type UserDivision = {
  active: boolean;
  id: number;
  name: string;
  description: string;
  unp: string;
  isActive: boolean;
  dOn: Nullable<string>;
  dOff: Nullable<string>;
  customsAgent: boolean;
};

export type UserRole = {
  id: number;
  ruName: string;
  engName: string;
  search: string;
};

export type InviteUserPayload = {
  email: string;
  divisionId: number;
  roleId: number;
};

export type UserInvite = {
  email: string;
  userId: number;
  divisionId: number;
  unp: string;
  divisionName: string;
  roleName: string;
  roleId: number;
};

export type RegisterPayload = {
  email: string;
  lastName: string;
  firstName: string;
  middleName: string;
  password: string;
};

export type ChangePasswordPayload = {
  password: string;
  newPassword: string;
};

export type ResetPasswordPayload = {
  password: string;
  email: string;
  resetPasswordToken: string;
};
