import { Inject, Injectable } from '@angular/core';
import { APP_ENVIRONMENT } from '@shared/providers/environment.provider';
import { HttpClient } from '@angular/common/http';
import { Environment } from '../../types/environment';
import { Seller } from '../../types/seller';

@Injectable({
  providedIn: 'root',
})
export class SellerService {
  readonly API = `${this.env.apiUrl}/seller`;
  constructor(
    @Inject(APP_ENVIRONMENT) private readonly env: Environment,
    private readonly http: HttpClient,
  ) {}

  save(seller: Seller) {
    return this.http.post<Seller>(`${this.API}/save`, seller);
  }

  getById(id: number) {
    return this.http.get<Seller>(`${this.API}/getById?id=${id}`);
  }

  getAll() {
    return this.http.get<Seller[]>(`${this.API}/getAll`);
  }

  toSellerMap(value: Seller[], key: keyof Seller): Map<string, Seller> {
    return value.reduce((previousValue, currentValue) => {
      return previousValue.set(currentValue[key]?.toString(), currentValue);
    }, new Map());
  }
}
