import { OrganizationRouteNames, RouteNames } from '../../route-names';

export type MenuItem = {
  name: string;
  authenticated: boolean;
  path: string;
  roles?: [];
};

export const MENU_ITEMS = [
  {
    name: 'Канбан',
    authenticated: true,
    path: RouteNames.KANBAN_PAGE,
  },
];

export const DROPDOWN_MENU_ITEMS: MenuItem[] = [
  {
    name: 'Список компаний',
    roles: [],
    authenticated: true,
    path: OrganizationRouteNames.LIST,
  },
  {
    name: 'Управление компанией',
    roles: [],
    authenticated: true,
    path: OrganizationRouteNames.DETAILS,
  },
  {
    name: 'Каталоги',
    authenticated: true,
    path: RouteNames.CATALOGS_PAGE,
  },
];
