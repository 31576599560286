<div>
  <div class="flex gap-x-2 px-5 py-4">
    <div class="w-[40px]"></div>
    <div class="w-[250px]">Питомник</div>
    <div class="w-[250px]">Клиент</div>
    <div class="w-[150px]">Тип</div>
    <div class="w-[140px]">Коробки</div>
    <div class="w-[140px]">Тележки</div>
    <div class="w-[140px]">Паллеты</div>
    <div class="w-[40px]">Вес</div>
    <div class="w-[150px]"></div>
  </div>
  <tui-accordion [rounded]="false">
    @for (
      orderGroup of preparedOrderGroups$ | async;
      track orderGroup.original.id;
      let index = $index
    ) {
      <tui-accordion-item borders="top-bottom" [showArrow]="false">
        <div class="flex gap-x-2">
          <div class="w-[40px] rounded-xl bg-purple-200 text-center">
            {{ index + 1 }}
          </div>
          <div class="w-[250px] overflow-hidden disappearing">
            {{ orderGroup.original.farmName }}
          </div>
          <div class="w-[250px] overflow-hidden disappearing">
            {{ orderGroup.original.clientName }}
          </div>
          <div class="w-[150px] overflow-hidden disappearing">
            {{ orderGroup.computed.flowerTypeName }}
          </div>
          <div class="w-[140px] disappearing">
            {{ orderGroup.computed.boxCount }}
          </div>
          <div class="w-[140px] disappearing">
            {{ orderGroup.computed.cartCount }}
          </div>
          <div class="w-[140px] disappearing">
            {{ orderGroup.computed.palletCount }}
          </div>
          <div class="w-[140px] disappearing">
            {{ orderGroup.computed.totalWeight }}
          </div>
          <div class="w-50px">
            <button
              size="s"
              appearance="icon"
              (click)="
                $event.stopPropagation(); delete$.next(orderGroup.original.id)
              "
              tuiIconButton
              icon="tuiIconTrash"
            ></button>
          </div>
        </div>

        <ng-template tuiAccordionItemContent>
          <app-order-group [orderGroup]="orderGroup.original"></app-order-group>
        </ng-template>
      </tui-accordion-item>
    }
  </tui-accordion>
</div>
<div class="my-4">
  <button size="s" icon="tuiIconPlus" tuiButton (click)="create$.next()">
    Добавить группу
  </button>
</div>
