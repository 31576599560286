import { AuthStateModel } from '@store/auth/auth.typings';
import { Credentials, LoginResponse } from '../../types/auth';

export class Login {
  static readonly type = '[Auth] Login';
  constructor(public credentials: Credentials) {}
}

export class SyncState {
  static readonly type = '[Auth] Sync State';
  constructor(public state: AuthStateModel) {}
}
export class RefreshAuth {
  static readonly type = '[Auth] Refresh Auth';
  constructor(public auth: LoginResponse) {}
}
export class Logout {
  static readonly type = '[Auth] Logout';
}

export class SetUserDivisions {
  static readonly type = '[Auth] Set user divisions';
}

export class SetUserActiveDivision {
  static readonly type = '[Auth] Set user active division';
  constructor(public id: number) {}
}
