import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  OnInit,
} from '@angular/core';
import { TuiGroupModule, TuiTextfieldControllerModule } from '@taiga-ui/core';
import { TuiInputModule, TuiInputNumberModule } from '@taiga-ui/kit';
import { InputDateComponent } from '@shared/components/forms/input-date/input-date.component';
import { AutocompleteComponent } from '@shared/components/forms/autocomplete/autocomplete.component';
import { FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { TRAILERS, VEHICLES } from '@shared/constants/vehicles';
import { Store } from '@ngxs/store';
import {
  catchError,
  delay,
  filter,
  Subject,
  switchMap,
  tap,
  throwError,
} from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AsyncPipe } from '@angular/common';
import { AlertService } from '@shared/services/alert.service';
import { TuiActiveZoneModule } from '@taiga-ui/cdk';
import { CargoState } from '../../store/cargo.state';
import { CatalogsService } from '../../services/catalogs.service';
import { CargoService } from '../../services/cargo.service';
import { Cargo } from '../../../../types/cargo';

@Component({
  selector: 'app-main-info',
  standalone: true,
  imports: [
    TuiGroupModule,
    TuiInputModule,
    TuiInputNumberModule,
    InputDateComponent,
    AutocompleteComponent,
    TuiTextfieldControllerModule,
    ReactiveFormsModule,
    AsyncPipe,
    TuiActiveZoneModule,
  ],
  templateUrl: './main-info.component.html',
  styleUrl: './main-info.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainInfoComponent implements OnInit {
  update$ = new Subject<void>();
  form = this.fb.group({
    id: this.fb.nonNullable.control({ value: 0, disabled: true }),
    userId: this.fb.control<Nullable<number>>(null),
    creationDate: this.fb.nonNullable.control({
      value: '',
      disabled: true,
    }),
    transportType: '',
    trailerType: '',
    sellerId: this.fb.control<Nullable<number>>(null),
    sellerName: '',
    uploadDate: '',
  });

  readonly vehicles = VEHICLES;
  readonly trailers = TRAILERS;
  sellers$ = this.catalogs.sellers$;

  constructor(
    private readonly fb: FormBuilder,
    private readonly store: Store,
    private readonly destroyRef: DestroyRef,
    private readonly catalogs: CatalogsService,
    private readonly alert: AlertService,
    private readonly cargoService: CargoService,
  ) {}

  onZoneChange(focused: boolean) {
    if (!focused) {
      this.update$.next();
    }
  }

  fillSellerName(sellerId: Nullable<string>) {
    const name =
      this.catalogs.sellerMap.get(sellerId?.toString() ?? '')?.name ?? '';
    this.form.controls.sellerName.setValue(name);
  }

  fillFormOnStateChange() {
    this.store
      .select(CargoState.getSelectedCargo)
      .pipe(
        filter(state => state !== null),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(cargo => {
        if (cargo) {
          this.form.setValue({
            id: cargo.id,
            trailerType: cargo.trailerType,
            transportType: cargo.transportType,
            creationDate: cargo.creationDate,
            sellerId: cargo.sellerId,
            sellerName: cargo.sellerName,
            uploadDate: cargo.uploadDate,
            userId: cargo.userId,
          });
        }
      });
  }

  updateOnChanges() {
    this.update$
      .pipe(
        delay(0),
        filter(() => !this.form.pristine),
        switchMap(() => {
          const payload: Cargo = {
            ...this.form.getRawValue(),
            cargoPlacesQuantityJson: [],
            paymentsJson: [],
            orderGroups: [],
          };
          return this.cargoService.updateCargo(payload);
        }),
        tap(() => {
          this.form.markAsPristine();
          this.form.markAsUntouched();
          this.alert.showSuccess();
        }),
        catchError(err => {
          this.alert.showError(err.error?.error || 'Ошибка');
          return throwError(() => err);
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  ngOnInit(): void {
    this.updateOnChanges();
    this.fillFormOnStateChange();
  }
}
