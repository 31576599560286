import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  Input,
  OnInit,
} from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import {
  BehaviorSubject,
  catchError,
  delay,
  filter,
  map,
  of,
  Subject,
  switchMap,
  tap,
  combineLatest,
  Observable,
  withLatestFrom,
  merge,
  shareReplay,
  startWith,
} from 'rxjs';
import {
  TuiInputModule,
  TuiInputNumberModule,
  TuiIslandModule,
  TuiToggleModule,
} from '@taiga-ui/kit';
import { TuiActiveZoneModule, TuiLetModule } from '@taiga-ui/cdk';
import { TuiButtonModule, TuiTextfieldControllerModule } from '@taiga-ui/core';
import { AutocompleteComponent } from '@shared/components/forms/autocomplete/autocomplete.component';
import { AsyncPipe, JsonPipe, NgForOf } from '@angular/common';
import { TuiTableModule } from '@taiga-ui/addon-table';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AlertService } from '@shared/services/alert.service';
import { fromFormControlValue } from '@shared/utils/observables';
import {
  CargoPlaceType,
  cargoPlaceTypesMap,
} from '@shared/constants/cargo-places';
import { roundDecimal } from '@shared/utils/math';
import { Store } from '@ngxs/store';
import {
  getCargoPlaceQuantityByTrailerType,
  TrailerType,
} from '@shared/constants/vehicles';
import { RateService } from '@shared/services/rate.service';
import { OrderComponent } from './components/order/order.component';
import { CargoService } from '../../services/cargo.service';
import {
  CargoOrder,
  CargoOrderForm,
  CargoOrderGroup,
} from '../../../../types/cargo';
import { CatalogsService } from '../../services/catalogs.service';
import { OrderGroupStateService } from './services/order-group-state.service';
import { CargoState } from '../../store/cargo.state';

type GroupedPallet = {
  place: string;
  quantity: number;
  cost: number;
  currencyLetter: string;
};

type OrderGroupExceptOrders = Omit<CargoOrderGroup, 'orders'>;

const NO_TYPE = '(без типа)';
@Component({
  selector: 'app-order-group',
  standalone: true,
  imports: [
    TuiIslandModule,
    TuiActiveZoneModule,
    TuiInputModule,
    ReactiveFormsModule,
    TuiTextfieldControllerModule,
    AutocompleteComponent,
    TuiToggleModule,
    TuiInputNumberModule,
    OrderComponent,
    NgForOf,
    AsyncPipe,
    TuiTableModule,
    TuiLetModule,
    TuiButtonModule,
    JsonPipe,
  ],
  providers: [OrderGroupStateService],
  templateUrl: './order-group.component.html',
  styleUrl: './order-group.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderGroupComponent implements OnInit {
  orderGroupForm = this.fb.group({
    id: this.fb.control<Nullable<number>>(null),
    cargoId: this.fb.control<Nullable<number>>(null),
    farmId: this.fb.control<Nullable<number>>(null),
    farmName: '',
    clientId: this.fb.control<Nullable<number>>(null),
    clientName: '',
    flowerType: this.fb.control<Nullable<string>>({
      value: null,
      disabled: true,
    }),
    imported: this.fb.control<Nullable<boolean>>({
      value: null,
      disabled: true,
    }),
    orders: this.fb.array<FormGroup<CargoOrderForm>>([]),
  });

  tableHeaders: Array<{ name: string; value: keyof GroupedPallet }> = [
    {
      name: '',
      value: 'place',
    },
    {
      name: '',
      value: 'quantity',
    },
    {
      name: '',
      value: 'cost',
    },
  ];

  // totalCost$ = new BehaviorSubject(0);
  addOrder$ = new Subject<void>();
  update$ = new Subject<void>();
  removeOrder$ = new Subject<number>();

  orderGroup$ = new BehaviorSubject<Nullable<OrderGroupExceptOrders>>(null);

  isActiveZone$ = new BehaviorSubject(false);

  isImport$ = fromFormControlValue(this.orderGroupForm.controls.imported);
  farmId$ = fromFormControlValue(this.orderGroupForm.controls.farmId);
  clientId$ = fromFormControlValue(this.orderGroupForm.controls.clientId);

  formData$: Observable<{
    isImport: Nullable<boolean>;
    farmId: Nullable<number>;
    clientId: Nullable<number>;
  }> = combineLatest([this.isImport$, this.farmId$, this.clientId$]).pipe(
    map(([isImport, farmId, clientId]) => ({ isImport, farmId, clientId })),
  );

  readonly typesMap = cargoPlaceTypesMap();

  trailerType$ = this.store
    .select(CargoState.getSelectedCargo)
    .pipe(map(cargo => cargo?.trailerType ?? ''));

  groupedPallets$ = merge(
    this.orders.valueChanges.pipe(startWith([])),
    this.trailerType$,
  ).pipe(
    map(() => this.orderGroupForm.getRawValue()),
    withLatestFrom(this.trailerType$),
    map(([group, trailerType]) => {
      const orders = group.orders ?? [];
      const isImport = group.imported;
      const flowerType = group.flowerType;
      const totalPlaceQuantity = this.getQuantityByCargoPlaceType(orders);
      const totalPlaceWeight = this.getWeightByCargoPlaceType(orders);
      const trailer = getCargoPlaceQuantityByTrailerType(
        trailerType as TrailerType,
      );
      const places: GroupedPallet[] = Object.entries(totalPlaceQuantity).map(
        ([place, quantity]) => {
          const rateMapKey = this.rateService.getMapKey({
            cargoPlaceCode: place,
            flowerTypeId: flowerType,
            imported: isImport,
          });
          const rateDetails = this.catalogs.rateMap.get(rateMapKey);
          const limit = trailer
            ? this.catalogs.cargoPlaceMap.get(place)?.[trailer] ?? 0
            : 0;
          const rate = rateDetails?.rate ?? 0;
          const weight = totalPlaceWeight[place];
          const cost = rateDetails?.imported
            ? rate * weight
            : (rate / limit) * quantity;
          return {
            place: this.typesMap.get(place)?.name ?? NO_TYPE,
            quantity: roundDecimal(quantity),
            currencyLetter: rateDetails?.currencyLetter ?? '',
            cost:
              Number.isNaN(cost) || !Number.isFinite(cost)
                ? 0
                : roundDecimal(cost),
          };
        },
      );
      return places;
    }),
    shareReplay(),
  );

  constructor(
    private readonly cargoService: CargoService,
    private readonly fb: FormBuilder,
    private readonly destroyRef: DestroyRef,
    private readonly cdr: ChangeDetectorRef,
    protected readonly catalogs: CatalogsService,
    private readonly alert: AlertService,
    private readonly orderGroupStateService: OrderGroupStateService,
    private readonly store: Store,
    private readonly rateService: RateService,
  ) {}

  onZoneChange(focused: boolean) {
    if (!focused) {
      this.update$.next();
    }
    this.isActiveZone$.next(focused);
  }

  onUpdate() {
    this.update$
      .pipe(
        delay(0),
        filter(() => !this.orderGroupForm.pristine),
        switchMap(() =>
          this.cargoService.updateOrders(
            this.orderGroupForm.controls.orders.getRawValue(),
          ),
        ),
        switchMap(() =>
          this.cargoService.updateOrderGroup(
            this.orderGroupForm.getRawValue() as CargoOrderGroup,
          ),
        ),
        tap(() => {
          this.alert.showSuccess();
          this.orderGroupForm.markAsUntouched();
          this.orderGroupForm.markAsPristine();
          this.cargoService.saveRecalculatedValues$.next();
        }),
        catchError(() => {
          this.alert.showError();
          return of(null);
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  onAddOrderClick() {
    this.addOrder$
      .pipe(
        switchMap(() =>
          this.cargoService.createOrder(this.orderGroup$.value?.id ?? 0),
        ),
        withLatestFrom(this.isImport$),
        tap(([order, imported]) => {
          const cargoPlaces = this.catalogs.cargoPlaceMap.get(
            CargoPlaceType.PALLET_US,
          );
          const group = this.fb.group({
            ...order,
            cargoPlaceTypeName: imported ? CargoPlaceType.PALLET_US : '',
            cargoPlaceQuantityLimit: imported
              ? cargoPlaces?.quantityInTruck ?? null
              : null,
          });
          group.markAsTouched();
          group.markAsDirty();
          this.orders.push(group);
          this.setTouchedAndDirty();
          this.cdr.markForCheck();
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  onRemoveOrderClick() {
    this.removeOrder$
      .pipe(
        switchMap(id => this.cargoService.deleteOrder(id).pipe(map(() => id))),
        tap(id => {
          const orders = this.orders.getRawValue();
          const index = orders.findIndex(i => i.id === id);
          this.orders.removeAt(index);
          this.setTouchedAndDirty();
          this.cdr.markForCheck();
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  onImportChange() {
    this.orderGroupForm.controls.imported.valueChanges
      .pipe(
        tap(imported => {
          this.orderGroupForm.controls.orders.controls.forEach(group => {
            if (imported) {
              group.patchValue({
                cargoPlaceQuantityLimit: null,
                cargoPlaceQuantity: null,
                cargoPlaceTypeName: null,
                packageTypeName: null,
                packageQuantity: null,
                packageQuantityLimit: null,
                packageTypeCode: null,
              });
            } else {
              group.patchValue({
                countryLetter: null,
                airWaybillNumber: null,
                precooling: null,
                weight: null,
              });
            }
          });
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  onFarmChange() {
    const id = this.orderGroupForm.controls.farmId.value?.toString() ?? '';
    this.setFarmDetails(id);
    this.clearClientCodes();
    this.setDefaultCargoPlaceIfImport();
  }

  onClientChange() {
    const id = this.orderGroupForm.controls.clientId.value?.toString() ?? '';
    this.setClientName(id);
    this.clearClientCodes();
  }

  setTouchedAndDirty() {
    this.orderGroupForm.markAsTouched();
    this.orderGroupForm.markAsDirty();
  }

  setDefaultCargoPlaceIfImport() {
    this.orderGroupForm.controls.orders.controls.forEach(group => {
      group.controls.cargoPlaceTypeName.setValue(CargoPlaceType.PALLET_US);
    });
  }

  clearClientCodes() {
    this.orderGroupForm.controls.orders.controls.forEach(group => {
      group.controls.buyerCode.setValue(null);
    });
  }

  setFarmDetails(farmId: Nullable<string>) {
    if (!farmId) {
      this.orderGroupForm.patchValue({
        farmName: '',
        flowerType: '',
        imported: null,
      });
    } else {
      const farm = this.catalogs.farmMap.get(farmId.toString());
      this.orderGroupForm.patchValue({
        farmName: farm?.name ?? '',
        flowerType: farm?.flowerType,
        imported: farm?.imported,
      });
    }
  }

  setClientName(id: Nullable<string>) {
    const control = this.orderGroupForm.controls.clientName;
    if (!id) {
      control.setValue('');
    } else {
      const clientName = this.catalogs.clientMap.get(id.toString())?.name ?? '';
      control.setValue(clientName);
    }
  }

  get orders() {
    return this.orderGroupForm.controls.orders;
  }

  get ordersArray() {
    return this.orderGroupForm.controls.orders.controls as FormGroup[];
  }

  // currencyLetter$ = this.invoiceForm.controls.group.valueChanges.pipe(
  //   startWith(this.invoiceForm.controls.group.value),
  //   map(type => cargoGroupsMap.get(type)?.currencyLetter ?? ''),
  //   shareReplay(),

  // onPalletsChange() {
  //   this.groupedPallets$
  //     .pipe(
  //       filter(() => this.invoiceForm.dirty),
  //       tap(groupedPallets => {
  //         this.updateTotalCost(groupedPallets);
  //         this.updateTotalQuantity(groupedPallets);
  //       }),
  //     )
  //     .subscribe();
  // }

  // onInputChange() {
  //   this.invoiceForm.controls.import.valueChanges
  //     .pipe(
  //       tap(value => {
  //         if (value) {
  //           const placesControl = this.invoiceForm.controls.places;
  //           const onlyUsPallet = placesControl
  //             .getRawValue()
  //             .filter(place => place.pallet === PalletType.US);
  //           placesControl.clear();
  //           onlyUsPallet.forEach(place =>
  //             placesControl.push(this.fb.nonNullable.group(place)),
  //           );
  //           if (!placesControl.length) {
  //             this.onCreatePlace();
  //           }
  //         } else {
  //           this.invoiceForm.controls.grossWeight.setValue(0);
  //         }
  //       }),
  //       takeUntilDestroyed(this.destroyRef),
  //     )
  //     .subscribe();
  // }

  // onGroupChange() {
  //   this.invoiceForm.controls.group.valueChanges
  //     .pipe(
  //       tap(group => {
  //         const importControl = this.invoiceForm.controls.import;
  //         const currencyLetter = cargoGroupsMap.get(group)?.currencyLetter;
  //         if (currencyLetter === Currencies.USD) {
  //           importControl.enable();
  //         } else {
  //           importControl.disable();
  //           importControl.setValue(false);
  //         }
  //       }),
  //       takeUntilDestroyed(this.destroyRef),
  //     )
  //     .subscribe();
  // }

  getQuantityByCargoPlaceType(orders: CargoOrder[]) {
    const typeQuantity: Record<string, number> = {};

    return orders.reduce((prev, curr) => {
      const type = curr.cargoPlaceTypeName || NO_TYPE;
      return {
        ...prev,
        [type]: (prev[type] ?? 0) + (curr.cargoPlaceQuantity ?? 0),
      };
    }, typeQuantity);
  }

  getWeightByCargoPlaceType(orders: CargoOrder[]) {
    const typeWeight: Record<string, number> = {};

    return orders.reduce((prev, curr) => {
      const type = curr.cargoPlaceTypeName || NO_TYPE;
      return {
        ...prev,
        [type]: (prev[type] ?? 0) + (curr.weight ?? 0),
      };
    }, typeWeight);
  }
  //
  // updateTotalCost(groupedPallets: GroupedPallet[]) {
  //   const isImport = this.invoiceForm.controls.import.getRawValue();
  //   const grossWeight = this.invoiceForm.controls.grossWeight.getRawValue();
  //
  //   const totalCost = isImport
  //     ? roundDecimal(grossWeight)
  //     : roundDecimal(
  //         groupedPallets.reduce((previousValue, currentValue) => {
  //           return previousValue + currentValue.cost;
  //         }, 0),
  //       );
  //   this.totalCost$.next(totalCost);
  //
  //   this.invoiceForm.controls.cost.setValue(totalCost, {
  //     emitEvent: false,
  //   });
  // }
  //
  // updateTotalQuantity(groupedPallets: GroupedPallet[]) {
  //   const totalQuantity = roundDecimal(
  //     groupedPallets.reduce((previousValue, currentValue) => {
  //       return previousValue + currentValue.quantity;
  //     }, 0),
  //   );
  //   this.invoiceForm.controls.quantity.setValue(totalQuantity, {
  //     emitEvent: false,

  get columns() {
    return [...this.tableHeaders.map(c => c.value)];
  }

  ngOnInit() {
    this.onUpdate();
    this.onAddOrderClick();
    this.onRemoveOrderClick();
    this.onImportChange();
    // this.onPalletsChange();
    // this.onInputChange();
    // this.onGroupChange();
    // this.orderGroupForm.controls.orders.valueChanges.subscribe(v => {
    //   console.log('changes', v);
    // });
  }

  @Input() set orderGroup({ orders, ...rest }: CargoOrderGroup) {
    this.orderGroup$.next(rest);
    this.orderGroupForm.patchValue(rest, { emitEvent: false });
    this.orderGroupForm.controls.orders.clear({ emitEvent: false });
    const ordersForms = orders.map(order =>
      this.fb.group({
        ...order,
        packageTypeName: this.fb.control({
          value: order.packageTypeName,
          disabled: true,
        }),
        packageQuantityLimit: this.fb.control({
          value: order.packageQuantityLimit,
          disabled: true,
        }),
        cargoPlaceQuantityLimit: this.fb.control({
          value: order.cargoPlaceQuantityLimit,
          disabled: true,
        }),
        cargoPlaceQuantity: this.fb.control({
          value: order.cargoPlaceQuantity,
          disabled: true,
        }),
      }),
    );

    ordersForms.forEach((form, index) => {
      this.orderGroupForm.controls.orders.push(form, {
        emitEvent: index === ordersForms.length - 1,
      });
    });
  }
}
