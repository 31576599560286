import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { AuthService } from '@shared/services/auth.service';
import { Router } from '@angular/router';
import {
  BroadcastChannelService,
  MessageTypes,
} from '@shared/services/broadcast-channel.service';
import { tap } from 'rxjs';
import { AuthStateModel } from './auth.typings';
import { Login, Logout, RefreshAuth, SyncState } from './auth.actions';
import { RouteNames } from '../../route-names';

const authStateBroadcastChannel = new BroadcastChannel('ngxs-sync');

const initialState = () => {
  return {
    token: null,
    user: null,
    refreshToken: null,
    role: null,
  };
};
@State<AuthStateModel>({
  name: 'auth',
  defaults: initialState(),
})
@Injectable()
export class AuthState {
  constructor(
    private readonly auth: AuthService,
    private readonly store: Store,
    private readonly router: Router,
    private readonly broadcastChannel: BroadcastChannelService,
  ) {
    authStateBroadcastChannel.addEventListener(
      'message',
      (event: MessageEvent<AuthStateModel>) => {
        this.store.dispatch(new SyncState(event.data));
      },
    );
  }

  @Action(SyncState)
  syncState(ctx: StateContext<AuthStateModel>, action: SyncState) {
    ctx.setState(action.state);
  }

  @Action(Login)
  login(ctx: StateContext<AuthStateModel>, action: Login) {
    return this.auth.login(action.credentials).pipe(
      tap(data => {
        ctx.patchState({
          token: data.jwtToken,
          refreshToken: data.refreshToken,
          user: data.user,
          role: data.role,
        });

        authStateBroadcastChannel.postMessage(ctx.getState());
        this.router.navigate([RouteNames.KANBAN_PAGE]);
        this.broadcastChannel.postMessage({
          type: MessageTypes.NAVIGATE_KANBAN,
        });
      }),
    );
  }

  @Action(RefreshAuth)
  refreshAuth(ctx: StateContext<AuthStateModel>, action: RefreshAuth) {
    const data = action.auth;
    ctx.patchState({
      token: data.jwtToken,
      user: data.user,
      refreshToken: data.refreshToken,
      role: data.role,
    });
    authStateBroadcastChannel.postMessage(ctx.getState());
  }

  @Action(Logout)
  logout(ctx: StateContext<AuthStateModel>) {
    ctx.patchState(initialState());
    this.router.navigate([RouteNames.LOGIN_PAGE]);
    this.broadcastChannel.postMessage({
      type: MessageTypes.NAVIGATE_LOGIN,
    });
  }

  @Selector()
  static getUser(state: AuthStateModel) {
    return state.user;
  }

  @Selector()
  static getToken(state: AuthStateModel) {
    return state.token;
  }

  @Selector()
  static getRefreshToken(state: AuthStateModel) {
    return state.refreshToken;
  }

  @Selector()
  static isAuthenticated(state: AuthStateModel) {
    return !!state.user?.email;
  }

  @Selector()
  static getUserActiveDivision(state: AuthStateModel) {
    return state.user?.divisionId;
  }
}
