import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ScrollTopButtonComponent } from '@shared/components/scroll-top-button/scroll-top-button.component';
import { ActivatedRoute } from '@angular/router';
import { take } from 'rxjs';
import { Store } from '@ngxs/store';
import { FetchUpdates } from '@store/catalogs/catalogs.actions';
import { MainInfoComponent } from './components/main-info/main-info.component';
import { PaymentsComponent } from './components/payments/payments.component';
import { OrderGroupComponent } from './components/order-group/order-group.component';
import { OrderGroupsComponent } from './components/order-groups/order-groups.component';
import { CargoService } from './services/cargo.service';
import { CatalogsService } from './services/catalogs.service';
import { CargoCharacteristicsComponent } from './components/cargo-characteristics/cargo-characteristics.component';

@Component({
  selector: 'app-cargo',
  standalone: true,
  imports: [
    ScrollTopButtonComponent,
    MainInfoComponent,
    PaymentsComponent,
    OrderGroupComponent,
    OrderGroupsComponent,
    CargoCharacteristicsComponent,
  ],
  providers: [CatalogsService],
  templateUrl: './cargo.component.html',
  styleUrl: './cargo.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CargoComponent implements OnInit {
  constructor(
    private readonly cargoService: CargoService,
    private readonly route: ActivatedRoute,
    private readonly catalogs: CatalogsService,
    private readonly store: Store,
  ) {}

  ngOnInit() {
    this.store.dispatch(new FetchUpdates());
    this.catalogs.preload();
    const id = this.route.snapshot.params.id as number;
    this.cargoService.setSelectedCargo(id).pipe(take(1)).subscribe();
  }
}
