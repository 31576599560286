export enum RouteNames {
  MAIN_PAGE = '',
  LOGIN_PAGE = 'login',
  FORBIDDEN_PAGE = 'forbidden',
  KANBAN_PAGE = 'kanban',
  NOMENCLATURE = 'nomenclature',
  USER = 'user',
  CATALOGS_PAGE = 'catalogs',
  RESET_PASSWORD_PAGE = 'reset-password',
  REGISTER_PAGE = 'register',
  CALCULATOR = 'calculator',
  CARGO_PAGE = 'cargo',
}
export enum OrganizationRouteNames {
  MAIN = 'organization',
  LIST = 'organization/list',
  DETAILS = 'organization/:id',
}
