import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AuthState } from '@store/auth/auth.state';
import { Store } from '@ngxs/store';
import { TuiSvgModule } from '@taiga-ui/core';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [TuiSvgModule, AsyncPipe],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent {
  isAuthenticated$ = this.store.select(AuthState.isAuthenticated);
  constructor(private readonly store: Store) {}
}
