import { Inject, Injectable } from '@angular/core';
import { APP_ENVIRONMENT } from '@shared/providers/environment.provider';
import { HttpClient } from '@angular/common/http';
import { Environment } from '../../types/environment';
import { CargoPlace } from '../../types/cargo-place';

@Injectable({
  providedIn: 'root',
})
export class CargoPlaceService {
  readonly API = `${this.env.apiUrl}/cargoPlaceClassifier`;
  constructor(
    @Inject(APP_ENVIRONMENT) private readonly env: Environment,
    private readonly http: HttpClient,
  ) {}

  create() {
    return this.http.post<CargoPlace>(`${this.API}/create`, null);
  }

  update(cargoPlace: CargoPlace) {
    return this.http.post<CargoPlace>(`${this.API}/save`, cargoPlace);
  }

  getById(id: number) {
    return this.http.get<CargoPlace>(`${this.API}/getById?id=${id}`);
  }

  getAll() {
    return this.http.get<CargoPlace[]>(`${this.API}/getAll`);
  }

  toCargoPlaceMap(
    value: CargoPlace[],
    key: keyof CargoPlace,
  ): Map<string, CargoPlace> {
    return value.reduce((previousValue, currentValue) => {
      return previousValue.set(currentValue[key]?.toString(), currentValue);
    }, new Map());
  }
}
