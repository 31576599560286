import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { joinObjectEntries } from '@shared/utils/common';
import { map, Observable } from 'rxjs';
import { APP_ENVIRONMENT } from '@shared/providers/environment.provider';
import {
  ChangePasswordPayload,
  Credentials,
  InviteUserPayload,
  LoginResponse,
  OrganizationUser,
  RegisterPayload,
  ResetPasswordPayload,
  UserDivision,
  UserInvite,
  UserPersonalData,
  UserRole,
} from '../../types/auth';
import { Environment } from '../../types/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  readonly USER = `${this.env.apiUrl}/user`;
  readonly DIVISION = `${this.env.apiUrl}/division`;
  readonly ROLE = `${this.env.apiUrl}/role`;
  constructor(
    @Inject(APP_ENVIRONMENT) private readonly env: Environment,
    private readonly router: Router,
    private readonly http: HttpClient,
  ) {}

  login(credentials: Credentials) {
    return this.http.post<LoginResponse>(`${this.USER}/signIn`, {
      email: credentials.login,
      password: credentials.password,
    });
  }

  refreshToken(refreshToken: string) {
    return this.http.post<LoginResponse>(
      `${this.USER}/refreshToken`,
      refreshToken,
    );
  }

  getUserPersonalData() {
    return this.http.get<UserPersonalData>(`${this.USER}/getUserPersonalData`);
  }

  saveUserPersonalData(payload: UserPersonalData) {
    return this.http.post(`${this.USER}/saveUserPersonalData`, payload);
  }

  getActiveUserByDivisionId(divisionId: number) {
    return this.http.get<UserPersonalData[]>(
      `${this.USER}/getActiveUsersByDivisionId?divisionId=${divisionId}`,
    );
  }

  getAllUserByDivisionId(divisionId: number) {
    return this.http.get<OrganizationUser[]>(
      `${this.USER}/getByDivisionId?divisionId=${divisionId}`,
    );
  }

  getUserDivisions() {
    return this.http.get<UserDivision[]>(`${this.DIVISION}/getByUserId`);
  }

  changeActiveDivision(id: number) {
    return this.http.post(
      `${this.USER}/changeActiveDivisionId?divisionId=${id}`,
      null,
    );
  }

  getAllRoles() {
    return this.http
      .get<UserRole[]>(`${this.ROLE}/getAll`)
      .pipe(
        map(roles =>
          roles.map(role => ({ ...role, search: `${role.id}-${role.ruName}` })),
        ),
      );
  }

  inviteUser(payload: InviteUserPayload) {
    const params = joinObjectEntries(payload).slice(1);
    return this.http.post<void>(`${this.USER}/invite?${params}`, null);
  }

  setUserActive(payload: {
    selectedUserId: number;
    divisionId: number;
    isActive: boolean;
  }) {
    const params = joinObjectEntries(payload).slice(1);
    return this.http.post<void>(`${this.USER}/setIsActive?${params}`, null);
  }

  getInviteInfo(uniqueKey: string) {
    return this.http.get<UserInvite>(
      `${this.USER}/getInviteInfo?uniqueKey=${uniqueKey}`,
    );
  }

  getInviteInfoByUserId(userId: number) {
    return this.http.get<UserInvite[]>(
      `${this.USER}/getInviteInfoByUserId?userId=${userId}`,
    );
  }

  acceptInvite(invite: UserInvite) {
    return this.http.post<void>(`${this.USER}/acceptInvite`, invite);
  }

  register(payload: RegisterPayload) {
    return this.http.post<void>(`${this.USER}/register`, payload);
  }

  changePassword(payload: ChangePasswordPayload): Observable<string> {
    return this.http.post(`${this.USER}/changePassword`, payload, {
      responseType: 'text',
    });
  }

  sendResetPasswordMessage(email: string) {
    return this.http.post(
      `${this.USER}/sendBriefForResetPassword?email=${email}`,
      null,
      {
        responseType: 'text',
      },
    );
  }

  resetPassword(payload: ResetPasswordPayload) {
    return this.http.post(`${this.USER}/resetPassword`, payload, {
      responseType: 'text',
    });
  }

  setUserDescription({
    selectedUserId,
    divisionId,
    description,
  }: {
    selectedUserId: number;
    divisionId: number;
    description: string;
  }) {
    return this.http.post<void>(
      `${this.USER}/setDescription?selectedUserId=${selectedUserId}&divisionId=${divisionId}`,
      description,
    );
  }

  setUserRole(payload: {
    selectedUserId: number;
    divisionId: number;
    roleId: Nullable<number>;
  }) {
    const params = joinObjectEntries(payload).slice(1);
    return this.http.post<void>(`${this.USER}/setRoleId?${params}`, null);
  }
}
