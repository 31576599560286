import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Injector,
  OnInit,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  FormControlDirective,
  FormsModule,
  NG_VALUE_ACCESSOR,
  NgControl,
  ReactiveFormsModule,
} from '@angular/forms';
import { TuiCheckboxModule } from '@taiga-ui/kit';
import { map, Observable, startWith } from 'rxjs';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'app-checkbox',
  standalone: true,
  imports: [TuiCheckboxModule, ReactiveFormsModule, FormsModule, AsyncPipe],
  templateUrl: './checkbox.component.html',
  styleUrl: './checkbox.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true,
    },
  ],
})
export class CheckboxComponent implements ControlValueAccessor, OnInit {
  formControl: FormControl;

  value$: Observable<boolean>;
  constructor(private readonly injector: Injector) {}

  onChange: any = () => {};
  onTouch: any = () => {};
  ngOnInit() {
    const ngControl = this.injector.get(NgControl);
    this.formControl = (ngControl as FormControlDirective).form;
    this.value$ = this.formControl.valueChanges.pipe(
      startWith(this.formControl.getRawValue()),
      map(value => value ?? false),
    );
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(): void {}
}
