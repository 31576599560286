<tui-loader
  [inheritColor]="true"
  [overlay]="true"
  [showLoader]="(loading$ | async) ?? false"
>
  <tui-island class="min-w-[430px] p-6">
    <h2
      class="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl"
    >
      Регистрация
    </h2>
    <div class="flex flex-col gap-y-2 my-6">
      <tui-input [formControl]="info.controls.divisionName" tuiTextfieldSize="m"
        >Компания</tui-input
      >
      <tui-input [formControl]="info.controls.roleName" tuiTextfieldSize="m"
        >Роль</tui-input
      >
      <tui-input [formControl]="form.controls.email" tuiTextfieldSize="m"
        >Email</tui-input
      >
      <tui-input [formControl]="form.controls.lastName" tuiTextfieldSize="m"
        >Фамилия</tui-input
      >
      <tui-input [formControl]="form.controls.firstName" tuiTextfieldSize="m"
        >Имя</tui-input
      >
      <tui-input [formControl]="form.controls.middleName" tuiTextfieldSize="m"
        >Отчество</tui-input
      >
      <tui-input-password
        [formControl]="form.controls.password"
        tuiTextfieldSize="m"
        >Пароль</tui-input-password
      >
      <tui-input-password
        [formControl]="form.controls.passwordConfirm"
        tuiTextfieldSize="m"
        >Подтверждение пароля</tui-input-password
      >
    </div>
    <button
      (click)="register()"
      [disabled]="!form.valid"
      class="w-full"
      tuiButton
      appearance="primary"
    >
      Зарегистрироваться
    </button>
  </tui-island>
</tui-loader>
