import { CargoPlace } from '../../types/cargo-place';

export enum VehicleType {
  TRUCK = 'truck',
}
export enum TrailerType {
  SEMITRAILER = 'semitrailer',
  REFRIGERATOR = 'refrigerator',
  ROAD_TRAIN = 'road_train',
}

export const VEHICLES = [
  {
    name: 'Авто',
    value: VehicleType.TRUCK,
  },
];

export const getCargoPlaceQuantityByTrailerType = (
  type: TrailerType,
):
  | keyof Pick<
      CargoPlace,
      'quantityInTruck' | 'quantityInRoadtrain' | 'quantityInRefrigerator'
    >
  | null => {
  if (type === TrailerType.SEMITRAILER) {
    return 'quantityInTruck';
  }
  if (type === TrailerType.REFRIGERATOR) {
    return 'quantityInRefrigerator';
  }
  if (type === TrailerType.ROAD_TRAIN) {
    return 'quantityInRoadtrain';
  }
  return null;
};
export const TRAILERS = [
  {
    name: 'Полуприцеп',
    value: TrailerType.SEMITRAILER,
  },
  {
    name: 'Рефрежератор',
    value: TrailerType.REFRIGERATOR,
  },
  {
    name: 'Автопоезд',
    value: TrailerType.ROAD_TRAIN,
  },
];
