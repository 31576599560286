import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  OnInit,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  BehaviorSubject,
  catchError,
  EMPTY,
  map,
  of,
  switchMap,
  take,
  tap,
} from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AuthService } from '@shared/services/auth.service';
import {
  TuiButtonModule,
  TuiLoaderModule,
  TuiTextfieldControllerModule,
} from '@taiga-ui/core';
import { AsyncPipe } from '@angular/common';
import {
  TuiInputModule,
  TuiInputPasswordModule,
  TuiIslandModule,
} from '@taiga-ui/kit';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { passwordConfirmValidator } from '@shared/validators/password-confirm.validator';
import { AlertService } from '@shared/services/alert.service';
import { UserInvite } from '../../../../types/auth';
import { RouteNames } from '../../../../route-names';

@Component({
  selector: 'app-user-register-form',
  standalone: true,
  imports: [
    TuiLoaderModule,
    AsyncPipe,
    TuiInputModule,
    TuiTextfieldControllerModule,
    TuiInputPasswordModule,
    TuiIslandModule,
    TuiButtonModule,
    ReactiveFormsModule,
  ],
  templateUrl: './user-register-form.component.html',
  styleUrl: './user-register-form.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserRegisterFormComponent implements OnInit {
  invite$ = new BehaviorSubject<Nullable<UserInvite>>(null);
  loading$ = new BehaviorSubject(false);

  form = this.fb.group(
    {
      email: this.fb.nonNullable.control({ value: '', disabled: true }, [
        Validators.required,
        Validators.email,
      ]),
      lastName: this.fb.nonNullable.control('', Validators.required),
      firstName: this.fb.nonNullable.control('', Validators.required),
      middleName: this.fb.nonNullable.control(''),
      password: this.fb.nonNullable.control('', Validators.required),
      passwordConfirm: this.fb.control('', Validators.required),
    },
    {
      validators: [passwordConfirmValidator()],
    },
  );

  info = this.fb.group({
    divisionName: this.fb.control({ value: '', disabled: true }),
    roleName: this.fb.control({ value: '', disabled: true }),
  });

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly destroyRef: DestroyRef,
    private readonly auth: AuthService,
    private readonly fb: FormBuilder,
    private readonly alert: AlertService,
    private readonly router: Router,
  ) {}

  register() {
    const { passwordConfirm, ...payload } = this.form.getRawValue();
    this.auth
      .register(payload)
      .pipe(
        take(1),
        tap(() => {
          this.alert.showSuccess('Пользователь зарегистрирован');
          this.router.navigate([RouteNames.LOGIN_PAGE]);
        }),
        catchError(() => {
          this.alert.showError();
          return EMPTY;
        }),
      )
      .subscribe();
  }

  ngOnInit() {
    this.activatedRoute.queryParams
      .pipe(
        tap(() => this.loading$.next(true)),
        map(queryParams => queryParams.uniqueKey as string),
        switchMap(uniqueKey => {
          return this.auth.getInviteInfo(uniqueKey);
        }),
        tap(invite => {
          this.invite$.next(invite);
          this.form.patchValue({
            email: invite.email,
          });
          this.info.setValue({
            divisionName: invite.divisionName,
            roleName: invite.roleName,
          });
          this.loading$.next(false);
        }),
        catchError(() => {
          this.loading$.next(false);
          return of(null);
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }
}
