import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  OnInit,
} from '@angular/core';
import { FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { Store } from '@ngxs/store';
import { TuiInputModule, TuiInputNumberModule } from '@taiga-ui/kit';
import { TuiTextfieldControllerModule } from '@taiga-ui/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { tap, combineLatest } from 'rxjs';
import { CargoState } from '../../store/cargo.state';
import { CatalogsService } from '../../services/catalogs.service';
import { Cargo } from '../../../../types/cargo';

@Component({
  selector: 'app-cargo-characteristics',
  standalone: true,
  imports: [
    TuiInputModule,
    TuiTextfieldControllerModule,
    ReactiveFormsModule,
    TuiInputNumberModule,
  ],
  templateUrl: './cargo-characteristics.component.html',
  styleUrl: './cargo-characteristics.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CargoCharacteristicsComponent implements OnInit {
  form = this.fb.group({
    cargoPlaceTotal: '',
    boxCount: this.fb.control<Nullable<number>>(null),
    sum: '',
  });

  cargo$ = this.store.select(CargoState.getSelectedCargo);
  orderGroups$ = this.store.select(CargoState.getOrderGroups);
  constructor(
    private readonly fb: FormBuilder,
    private readonly store: Store,
    private readonly destroyRef: DestroyRef,
    private readonly catalogs: CatalogsService,
  ) {}

  getCargoPlaceTotal(cargo: Nullable<Cargo>) {
    const cargoPlaceMap = this.catalogs.cargoPlaceMap;
    return (
      cargo?.cargoPlacesQuantityJson
        .map(
          q => `${q.quantity} ${cargoPlaceMap.get(q.type ?? '')?.name ?? ''}`,
        )
        .join(' / ') ?? ''
    );
  }

  getSum(cargo: Nullable<Cargo>) {
    return (
      cargo?.paymentsJson
        .map(q => `${q.sum} ${q.currencyLetter}`)
        .join(' / ') ?? ''
    );
  }

  calculateBoxQuantity() {
    this.orderGroups$
      .pipe(
        tap(groups => {
          const boxCount = groups.reduce((previousValue, currentValue) => {
            return (
              previousValue +
              currentValue.orders.reduce((p, c) => {
                return p + (c.packageQuantity ?? 0);
              }, 0)
            );
          }, 0);
          this.form.controls.boxCount.setValue(boxCount);
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  fillPlaceAndSum() {
    combineLatest([this.cargo$, this.catalogs.cargoPlaces$])
      .pipe(
        tap(([cargo]) => {
          this.form.patchValue({
            cargoPlaceTotal: this.getCargoPlaceTotal(cargo),
            sum: this.getSum(cargo),
          });
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  ngOnInit() {
    this.form.disable();
    this.calculateBoxQuantity();
    this.fillPlaceAndSum();
  }
}
