export enum CargoPlaceType {
  PALLET_US = 'pallet_us',
  PALLET_EUR = 'pallet_eur',
  PALLET_FIN = 'pallet_fin',
  CART = 'cart',
}
type CargoPlaceTypeItem = {
  value: string;
  name: string;
};
export const CARGO_PLACE_TYPES: CargoPlaceTypeItem[] = [
  {
    name: 'Палета US',
    value: CargoPlaceType.PALLET_US,
  },
  {
    name: 'Палета EUR',
    value: CargoPlaceType.PALLET_EUR,
  },
  {
    name: 'Палета FIN',
    value: CargoPlaceType.PALLET_FIN,
  },
  {
    name: 'Тележка',
    value: CargoPlaceType.CART,
  },
];

export function cargoPlaceTypesMap(): Map<string, CargoPlaceTypeItem> {
  return CARGO_PLACE_TYPES.reduce((previousValue, currentValue) => {
    return previousValue.set(currentValue.value?.toString(), currentValue);
  }, new Map());
}
