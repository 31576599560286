import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { TuiButtonModule } from '@taiga-ui/core';
import { AsyncPipe } from '@angular/common';
import { Store } from '@ngxs/store';
import {
  catchError,
  map,
  Observable,
  of,
  Subject,
  switchMap,
  withLatestFrom,
  combineLatest,
  tap,
} from 'rxjs';
import { TuiAccordionModule } from '@taiga-ui/kit';
import { AlertService } from '@shared/services/alert.service';
import { CargoPlaceType } from '@shared/constants/cargo-places';
import { roundDecimal } from '@shared/utils/math';
import { CargoService } from '../../services/cargo.service';
import { OrderGroupComponent } from '../order-group/order-group.component';
import { CargoState } from '../../store/cargo.state';
import { CargoOrderGroup } from '../../../../types/cargo';
import { CatalogsService } from '../../services/catalogs.service';

type PreparedOrderGroup = {
  original: CargoOrderGroup;
  computed: {
    flowerTypeName: string;
  } & OrderTotal;
};

type OrderTotal = {
  boxCount: number;
  cartCount: number;
  palletCount: number;
  totalWeight: number;
};

@Component({
  selector: 'app-order-groups',
  standalone: true,
  imports: [
    TuiButtonModule,
    AsyncPipe,
    OrderGroupComponent,
    TuiAccordionModule,
  ],
  templateUrl: './order-groups.component.html',
  styleUrl: './order-groups.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderGroupsComponent implements OnInit {
  orderGroups$ = this.store.select(CargoState.getOrderGroups);

  preparedOrderGroups$: Observable<PreparedOrderGroup[]> = combineLatest([
    this.orderGroups$,
    this.catalogs.flowerTypes$,
  ]).pipe(
    map(([groups]) => {
      const flowerTypesMap = this.catalogs.flowerTypesMap;
      return groups.map(group => {
        const initial: OrderTotal = {
          boxCount: 0,
          cartCount: 0,
          palletCount: 0,
          totalWeight: 0,
        };
        const total: OrderTotal = group.orders.reduce(
          (previousValue, currentValue) => {
            return {
              boxCount:
                previousValue.boxCount + (currentValue.packageQuantity ?? 0),
              cartCount:
                previousValue.cartCount +
                (CargoPlaceType.CART === currentValue.cargoPlaceTypeName
                  ? currentValue.cargoPlaceQuantity ?? 0
                  : 0),
              palletCount:
                previousValue.palletCount +
                ([
                  CargoPlaceType.PALLET_US,
                  CargoPlaceType.PALLET_EUR,
                  CargoPlaceType.PALLET_FIN,
                ].includes(currentValue.cargoPlaceTypeName as CargoPlaceType)
                  ? currentValue.cargoPlaceQuantity ?? 0
                  : 0),
              totalWeight:
                previousValue.totalWeight + (currentValue.weight ?? 0),
            };
          },
          initial,
        );
        const totalNormalized: OrderTotal = Object.entries(total).reduce(
          (p, [key, value]) => {
            return {
              ...p,
              [key]: roundDecimal(value),
            };
          },
          {} as OrderTotal,
        );
        return {
          original: group,
          computed: {
            flowerTypeName:
              flowerTypesMap.get(group.flowerType ?? '')?.name ?? '',
            ...totalNormalized,
          },
        };
      });
    }),
  );

  create$ = new Subject<void>();
  delete$ = new Subject<number>();

  cargoId$ = this.store
    .select(CargoState.getSelectedCargo)
    .pipe(map(cargo => cargo?.id ?? 0));

  constructor(
    protected readonly cargoService: CargoService,
    private readonly store: Store,
    private readonly alert: AlertService,
    private readonly catalogs: CatalogsService,
  ) {}

  onCreateOrderGroup() {
    this.create$
      .pipe(
        withLatestFrom(this.cargoId$),
        switchMap(([, cargoId]) => {
          return this.cargoService.createOrderGroup(cargoId);
        }),
        tap(() => {
          this.cargoService.saveRecalculatedValues$.next();
        }),
        catchError(() => {
          this.alert.showError();
          return of(null);
        }),
      )
      .subscribe();
  }

  onDeleteOrderGroup() {
    this.delete$
      .pipe(
        switchMap(id => {
          return this.cargoService.deleteOrderGroup(id);
        }),
        tap(() => {
          this.cargoService.saveRecalculatedValues$.next();
        }),
        catchError(() => {
          this.alert.showError();
          return of(null);
        }),
      )
      .subscribe();
  }

  ngOnInit() {
    this.onCreateOrderGroup();
    this.onDeleteOrderGroup();
  }
}
