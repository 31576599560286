import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { produce } from 'immer';
import { CargoStateModel } from '../types';
import {
  AddOrderGroup,
  DeleteOrderGroup,
  SetSelectedCargo,
  UpdateOrderGroup,
} from './cargo.actions';

@State<CargoStateModel>({
  name: 'cargo',
  defaults: {
    selectedCargo: null,
  },
})
@Injectable()
export class CargoState {
  @Action(SetSelectedCargo)
  setSelectedCargo(
    ctx: StateContext<CargoStateModel>,
    action: SetSelectedCargo,
  ) {
    ctx.patchState({
      selectedCargo: action.cargo,
    });
  }

  @Action(AddOrderGroup)
  addOrderGroup(ctx: StateContext<CargoStateModel>, action: AddOrderGroup) {
    ctx.setState(
      produce(draft => {
        draft.selectedCargo?.orderGroups.push(action.orderGroup);
      }),
    );
  }

  @Action(UpdateOrderGroup)
  updateOrderGroup(
    ctx: StateContext<CargoStateModel>,
    action: UpdateOrderGroup,
  ) {
    ctx.setState(
      produce(draft => {
        const orderGroups = draft.selectedCargo?.orderGroups ?? [];
        const orderGroupsIndex = orderGroups.findIndex(
          i => i.id === action.orderGroup.id,
        );
        draft.selectedCargo?.orderGroups.splice(
          orderGroupsIndex,
          1,
          action.orderGroup,
        );
      }),
    );
  }

  @Action(DeleteOrderGroup)
  deleteOrderGroup(
    ctx: StateContext<CargoStateModel>,
    action: DeleteOrderGroup,
  ) {
    ctx.setState(
      produce(draft => {
        const orderGroups = draft.selectedCargo?.orderGroups ?? [];
        const orderGroupsIndex = orderGroups.findIndex(i => i.id === action.id);
        draft.selectedCargo?.orderGroups.splice(orderGroupsIndex, 1);
      }),
    );
  }

  @Selector()
  static getSelectedCargo(state: CargoStateModel) {
    return state.selectedCargo;
  }

  @Selector()
  static getOrderGroups(state: CargoStateModel) {
    return state.selectedCargo?.orderGroups ?? [];
  }
}
