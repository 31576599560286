import { ChangeDetectionStrategy, Component, computed } from '@angular/core';
import { Store } from '@ngxs/store';
import {
  TuiButtonModule,
  TuiDataListModule,
  TuiTextfieldControllerModule,
} from '@taiga-ui/core';

import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { AuthState } from '@store/auth/auth.state';
import { FormsModule } from '@angular/forms';
import { TuiSelectModule } from '@taiga-ui/kit';
import { TuiLetModule } from '@taiga-ui/cdk';
import { NgForOf } from '@angular/common';
import { UserMenuComponent } from './components/user-menu/user-menu.component';
import { MENU_ITEMS } from './menu';
import { RouteNames } from '../../route-names';
import { DropdownMenuComponent } from './components/dropdown-menu/dropdown-menu.component';

@Component({
  selector: 'app-navbar',
  standalone: true,
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.scss',
  imports: [
    TuiButtonModule,
    RouterLink,
    UserMenuComponent,
    TuiDataListModule,
    FormsModule,
    TuiSelectModule,
    TuiTextfieldControllerModule,
    TuiLetModule,
    NgForOf,
    DropdownMenuComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavbarComponent {
  isAuthenticated = this.store.selectSignal(AuthState.isAuthenticated);

  availableMenuItems = computed(() => {
    return MENU_ITEMS.filter(menuItem => {
      if (menuItem?.authenticated) {
        return this.isAuthenticated();
      }
      return true;
    });
  });

  protected readonly MENU_ITEMS = MENU_ITEMS;
  protected readonly RouteNames = RouteNames;
  constructor(
    private readonly store: Store,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
  ) {}

  navigate(item: { path: string }) {
    this.router.navigate([item.path]);
  }
}
