import { Inject, Injectable } from '@angular/core';
import { Subject, switchMap } from 'rxjs';
import { Store } from '@ngxs/store';
import { HttpClient } from '@angular/common/http';
import { APP_ENVIRONMENT } from '@shared/providers/environment.provider';
import {
  AddOrderGroup,
  DeleteOrderGroup,
  SetSelectedCargo,
  UpdateOrderGroup,
} from '../store/cargo.actions';
import { Environment } from '../../../types/environment';
import { Cargo, CargoOrder, CargoOrderGroup } from '../../../types/cargo';

@Injectable({
  providedIn: 'root',
})
export class CargoService {
  readonly API_CARGO = `${this.env.apiUrl}/cargo`;
  readonly API_ORDER_GROUP = `${this.env.apiUrl}/orderGroup`;
  readonly API_ORDER = `${this.env.apiUrl}/order`;

  saveRecalculatedValues$ = new Subject<void>();
  constructor(
    @Inject(APP_ENVIRONMENT)
    private readonly env: Environment,
    private readonly store: Store,
    private readonly http: HttpClient,
  ) {}

  getCargoById(id: number) {
    return this.http.get<Cargo>(`${this.API_CARGO}/getById?id=${id}`);
  }

  setSelectedCargo(id: number) {
    return this.getCargoById(id).pipe(
      switchMap(payload => this.store.dispatch(new SetSelectedCargo(payload))),
    );
  }

  updateCargo(cargo: Cargo) {
    return this.http
      .post<Cargo>(`${this.API_CARGO}/save`, cargo)
      .pipe(
        switchMap(updatedCargo =>
          this.store.dispatch(new SetSelectedCargo(updatedCargo)),
        ),
      );
  }

  createOrderGroup(cargoId: number) {
    return this.http
      .post<CargoOrderGroup>(
        `${this.API_ORDER_GROUP}/create?cargoId=${cargoId}`,
        null,
      )
      .pipe(switchMap(group => this.store.dispatch(new AddOrderGroup(group))));
  }

  updateOrderGroup(orderGroup: CargoOrderGroup) {
    return this.http
      .post<CargoOrderGroup>(`${this.API_ORDER_GROUP}/save`, orderGroup)
      .pipe(
        switchMap(orderGroupUpdated =>
          this.store.dispatch(new UpdateOrderGroup(orderGroupUpdated)),
        ),
      );
  }

  deleteOrderGroup(id: number) {
    return this.http
      .post<void>(`${this.API_ORDER_GROUP}/deleteById?id=${id}`, null)
      .pipe(switchMap(() => this.store.dispatch(new DeleteOrderGroup(id))));
  }

  createOrder(orderGroupId: number) {
    return this.http.post<CargoOrder>(
      `${this.API_ORDER}/create?orderGroupId=${orderGroupId}`,
      null,
    );
  }

  updateOrder(order: CargoOrder) {
    return this.http.post<CargoOrder>(`${this.API_ORDER}/save`, order);
  }

  updateOrders(orders: CargoOrder[]) {
    return this.http.post<CargoOrder[]>(`${this.API_ORDER}/updateAll`, orders);
  }

  deleteOrder(id: number) {
    return this.http.post<void>(`${this.API_ORDER}/deleteById?id=${id}`, null);
  }
}
